import React from 'react';

const RedRisingReview = () => {
  return (
    <div>
      <h1>Red Rising Review</h1>
      <p>This is where the review of the book Red Rising Review will go.</p>
    </div>
  );
};

export default RedRisingReview;


