import React from 'react';

const GoldenSonReview = () => {
  return (
    <div>
      <h1>Golden Son Review</h1>
      <p>This is where the review of the book Golden Son will go.</p>
    </div>
  );
};

export default GoldenSonReview;


