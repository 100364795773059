import React from 'react';

const MorningStarReview = () => {
  return (
    <div>
      <h1>Morning Star Review</h1>
      <p>This is where the review of the book Morning Star Review will go.</p>
    </div>
  );
};

export default MorningStarReview;


