import React from 'react';

const ATourismApp = () => {
  return (
    <div>
      <h1>A Tourism App</h1>
      <p>A Tourism App project.</p>
    </div>
  );
};

export default ATourismApp;

