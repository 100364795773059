import React from 'react';

const RamblerRegistrar = () => {
  return (
    <div>
      <h1>Rambler Registrar</h1>
      <p>Rambler Registrar project.</p>
    </div>
  );
};

export default RamblerRegistrar;

