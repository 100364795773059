import React from 'react';

const SparrowReview = () => {
  return (
    <div>
      <h1>SparrowReview</h1>
      <p>This is where the review of the Sparrow.</p>
    </div>
  );
};

export default SparrowReview;
