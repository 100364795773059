import React from 'react';

const WeatherApp = () => {
  return (
    <div>
      <h1>Weather App</h1>
      <p>Weather App project.</p>
    </div>
  );
};

export default WeatherApp;

