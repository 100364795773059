import React from 'react';

const HowToBlowUpAPipelineReview = () => {
  return (
    <div>
      <h1>How To Blow Up A Pipeline Review</h1>
      <p>This is where the review of the book How To Blow Up A Pipeline will go.</p>
    </div>
  );
};

export default HowToBlowUpAPipelineReview;

