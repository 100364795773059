import React from 'react';

const ReactNativeCreditCardApp = () => {
  return (
    <div>
      <h1>React Native Credit Card App</h1>
      <p>React Native Credit Card App project.</p>
    </div>
  );
};

export default ReactNativeCreditCardApp;

